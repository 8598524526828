<template>
	<div class="p-fluid p-formgrid p-grid">
        <div class="p-grid" v-if="inf.reemplazo && edicion">
            <div class="p-field p-col-12">
                <strong>{{$t('Vehículo de alquiler')}}</strong>
            </div>
            <div class="p-field p-col-7">
                <label>{{$t('Agencia')}}</label>
                <InputText v-model="inf.rent_agencia" type="text"/>
            </div>
            <div class="p-field p-col-2">
                <label>{{$t('Nº Días')}}</label>
                <InputText v-model="inf.rent_dias" type="number" min="0"/>
            </div>
            <div class="p-field p-col-3">
                <label>{{$t('Monto por día')}}</label>
                <InputText v-model="inf.rent_monto" type="number" min="0"/>
            </div>
            <div class="p-field p-col-4">
                <label>{{$t('Monto Total')}}</label>
                <InputText :value="inf.rent_monto*inf.rent_dias" type="number" min="0" disabled/>
            </div>
            <div class="p-field p-col-4">
                <label>{{$t('Deduction if applies')}}</label>
                <InputText v-model="inf.rent_deduc" type="number" min="0"/>
            </div>
            <div class="p-field p-col-4">
                <label>{{$t('Final to pay')}}</label>
                <InputText :value="inf.rent_monto*inf.rent_dias-inf.rent_deduc" type="number" min="0" disabled/>
            </div>
            <div class="p-field p-col-12">
                <strong>{{$t('Auto de reemplazo')}}</strong>
            </div>
            <div class="p-field p-col-4">
                <label>{{$t('Nº placa')}}</label>
                <InputText v-model="inf.rent_placa" type="text"/>
            </div>
            <div class="p-field p-col-4">
                <label>{{$t('Kilometraje de salida')}}</label>
                <InputText v-model="inf.rent_km" type="number" min="0"/>
            </div>
            <div class="p-field p-col-4">
                <label>{{$t('Gasolina')}}</label>
                <InputText v-model="inf.rent_gas" type="number" min="0"/>
            </div>
            <div class="p-field p-col-12">
                <strong>{{$t('Condiciones de devolución')}}</strong>
            </div>
            <div class="p-field p-col-8">
                <Checkbox v-model="inf.rent_verif1" :binary="true" :disabled="inf.estado.code>11"/>
                {{$t('Limpieza verificada. Cargo por limpieza')}}
            </div>
            <div class="p-field p-col-4">
                <InputText v-model="inf.rent_verif11" type="number" min="0"/>
            </div>
            <div class="p-field p-col-8">
                <Checkbox v-model="inf.rent_verif2" :binary="true" :disabled="inf.estado.code>11"/>
                {{$t('Gas verificado. Cargo por gas')}}
            </div>
            <div class="p-field p-col-4">
                <InputText v-model="inf.rent_verif22" type="number" min="0"/>
            </div>
        </div>
        <div class="p-grid" v-if="inf.reemplazo && !edicion">
            <div class="p-field p-col-12">
                <strong>{{$t('Vehículo de alquiler')}}</strong>
            </div>
            <div class="p-field p-col-7">
                <strong>{{$t('Agencia')}}: {{ inf.rent_agencia }}</strong>
            </div>
            <div class="p-field p-col-2">
                <strong>{{$t('Nº Días')}}: {{ inf.rent_dias }}</strong>
            </div>
            <div class="p-field p-col-3">
                <strong>{{$t('Monto por día')}}: {{ inf.rent_monto }}</strong>
            </div>
            <div class="p-field p-col-4">
                <strong>{{$t('Monto Total')}}: {{ inf.rent_monto*inf.rent_dias }}</strong>
            </div>
            <div class="p-field p-col-4">
                <strong>{{$t('Deduction if applies')}}: {{ inf.rent_deduc }}</strong>
            </div>
            <div class="p-field p-col-4">
                <strong>{{$t('Final to pay')}}: {{ inf.rent_monto*inf.rent_dias-inf.rent_deduc }}</strong>
            </div>
            <div class="p-field p-col-12">
                <strong>{{$t('Auto de reemplazo')}}</strong>
            </div>
            <div class="p-field p-col-4">
                <strong>{{$t('Nº placa')}}: {{ inf.rent_placa }}</strong>
            </div>
            <div class="p-field p-col-4">
                <strong>{{$t('Kilometraje de salida')}}: {{ inf.rent_km }}</strong>
            </div>
            <div class="p-field p-col-4">
                <strong>{{$t('Gasolina')}}: {{ inf.rent_gas }}</strong>
            </div>
        </div>
	</div>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
        props: {
            datos: {
                type: Object,
                default: null,
            },
            edit: {
                type: Boolean,
                default: false,
            },
        },
        data() {
			return {
				i18n: null,
                inf: this.datos,
                edicion: this.edit,
			}
		},
		created() {
			this.i18n = useI18nPlugin();
		},
        methods: {

        }
	}
</script>
